@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  h1 {
    @apply text-3xl font-serif text-indigo-900;
  }
  h2 {
    @apply text-2xl font-serif text-indigo-900;
  }
  h3 {
    @apply text-xl font-serif text-indigo-900;
  }
}

a {
  @apply underline text-indigo-900 text-opacity-60 hover:text-indigo-600;
}

p {
  @apply mt-1 mb-1;
}

.header {
  background-color: #212E57;
  @apply text-center; 
}
.footer {
  @apply bg-indigo-900 text-center py-2 absolute left-0 right-0 z-50;
}
.footer a {
  color: white;
}

.logo{
  display:block;
  transition: 0.2s transform ease-in-out;
}
.logo:hover {
  transform: scale(1.2)
}


/* Placeholder  */
[data-placeholder]::after {
  content: " ";
  box-shadow: 0 0 50px 9px rgba(254, 254, 254);
  position: absolute;
  top: 0;
  left: -100%;
  height: 100%;
  animation: load 1s infinite;
}
@keyframes load {
  0% {
    left: -100%;
  }
  100% {
    left: 150%;
  }
}

.cms-editor ul {
  margin-left: 40px;
  list-style-type: disc;
}

.cms-editor ul > li {
  margin: 5px;
}

.ad-item {
  @apply bg-white;
  transition: background-color 0.3s ease-in;
}

.ad-placeholder {
  @apply bg-gray-100;
}

.ad-placeholder::before {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  @apply bg-gray-500;
  border-radius: 5px;
  @apply text-gray-50;
  content: "Ad";
  font: 11px sans-serif;
  padding: 2px;
  text-align: center;
  width: 20px;
}


/** OUTBRAIN */

.AR_1.ob-ultra-strip-layout .ob-rec-text {
  color: #212E57 !important;
}


/** use a mix of media queries and inline style to have good CLS in both desktop and mobile */
.adsize_300_600 {
  min-width: 300px !important;
  min-height: 600px !important;
}

@screen md {
  .desktop_adsize_1000_300 {
    min-width: 1000px !important;
    min-height: 300px !important;
  }

  .desktop_adsize_336_280 {
    min-width: 336px !important;
    min-height: 280px !important;
  }
}

#vid-container0 {
  overflow-x: auto;
}